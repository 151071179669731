import React from 'react';
import PageHeader from '../PageHeader';
import ContactUsForm from './ContactUsForm';
import OfficeInfo from './OfficeInfo';

const Contact = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108930/headerBackgroundContact_lxkmce.jpg';

  return (
    <main>
      <PageHeader pageName="Contact Us" headerImage={background} />
      <ContactUsForm />
      <OfficeInfo />
    </main>
  );
};

export default Contact;
