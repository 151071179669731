import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Contact from '../components/Contact';

export default props => (
  <React.Fragment>
    <SEO
      title="Provo Treatment Center Contact | Mindset Family Therapy"
      description="Contact Mindset Family Therapy to start personalized care for you or your loved one. Mindset Family Therapy is a specialized treatment practice for OCD and related anxiety disorders in Utah."
      pathname="/contact"
    />
    <Layout>
      <Contact {...props} />
    </Layout>
  </React.Fragment>
);
